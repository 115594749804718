<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            LISAA School of Art & Design, (L'Institut Supérieur des Arts Appliqués) - французское частное учреждение прикладного художественного образования, основанное в 1986 году. Кампусы школы находятся в Париже, Ренне, Нанте, Страсбурге, Бордо и Тулузе. Школа (одна из немногих) признана Министерством культуры и коммуникаций Франции. Вы можете пройти обучение и получить диплом по анимации, видеоиграм, архитектуре интерьеров, дизайну, графическому дизайну и моде.
            <br>
            Это самая большая школа дизайна во Франции. Обучение в LISAA основано на работе над проектами и ​​в создано в тесном партнерстве с бизнесом. LISAA находится на вершине рейтинга среди школ, наиболее высоко оцениваемых профессионалами.
            <br>
            Сеть выпускников LISAA насчитывает более 5000 человек, которые работают по всему миру. LISAA предлагает своим студентам и выпускникам более 800 стажировок и предложений о работе, доступных на платформе школы. Обучение в школе проводят профессионалы индустрии.
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/watch?v=4LJ6JKFy3M4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template #speciality>
            Fashion & design
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="fa">Fashion</option>
                                <option value="iad">Interior Architecture & Design </option>
                                <option value="gdmd">Graphic Design & Motion Design</option>
                                <option value="avg">Animation & Video Games</option>
                                </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'fa'" id="fa">
                        <template #title>
                            Fashion 
                        </template>
                        <template #description>
                            <p>
                                LISAA обучает модельеров и стилистов, специализирующихся на создании выкройки и текстильном дизайне, в Париже и Нанте. LISAA разработала курсы по менеджменту, коммуникации и маркетингу для секторов моды и предметов роскоши, которые позволяют студентам работать полный рабочий день во время обучения.
                            </p>
                        </template>
                        <template #duree>
                            5 лет
                        </template>
                        <template #language>
                            французский или английский
                        </template>
                        <template #cost>
                            7.990-11.390 евро/год
                        </template>
                        <template #campus>
                            Париж, Нант, онлайн-обучение
                        </template>
                        <template #requirements>
                            французский или английский уровня B2, досье.
                        </template>
                        <template #start-time>
                            Сентябрь, Январь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'iad'" id="iad">
                        <template #title>
                            Interior Architecture & Design 
                        </template>
                        <template #description>
                            <p>
                                LISAA обучает дизайнеров и архитекторов интерьера. В конце учебной программы студентам предлагается уделить особое внимание сервисному дизайну, глобальному дизайну и т. д. Курс доступен в Париже, Нанте, Ренне, Страсбурге и Бордо
                            </p>
                        </template>
                        <template #duree>
                            5 лет
                        </template>
                        <template #language>
                             французский или английский 
                        </template>
                        <template #cost>
                            7.990-11.390 евро/год
                        </template>
                        <template #campus>
                            Париж, Ренн, Нант, Страсбург, Бордо, Тулуза
                        </template>
                        <template #requirements>
                            французский или английский уровня B2, досье.
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'gdmd'" id="gdmd">
                        <template #title>
                            Graphic Design & Motion Design
                        </template>
                        <template #description>
                            <p>
                                LISAA, школа графического дизайна, готовит профессионалов графического дизайна для печати и цифровых медиа: графических дизайнеров, моушн-дизайнеров, арт-директоров, веб-дизайнеров, типографов.
                            </p>
                        </template>
                        <template #duree>
                            5 лет
                        </template>
                        <template #language>
                             французский или английский 
                        </template>
                        <template #cost>
                            7.690-11.390 евро/год
                        </template>
                        <template #campus>
                            Париж, Ренн, Нант, Страсбург, Бордо, Тулуза
                        </template>
                        <template #requirements>
                            французский или английский уровня B2, досье.
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'avg'" id="avg">
                        <template #title>
                            Animation & Video Games
                        </template>
                        <template #description>
                            <p>
                                LISAA готовит специалистов по 2-D и 3-D анимации, видеоиграм и визуальным эффектам в Париже, Бордо и Тулузе.
                            </p>
                        </template>
                        <template #duree>
                            5 лет
                        </template>
                        <template #language>
                             французский или английский 
                        </template>
                        <template #cost>
                            7.990-11.390 евро/год
                        </template>
                        <template #campus>
                            Париж, Бордо, Тулуза
                        </template>
                        <template #requirements>
                            французский или английский уровня B2, досье.
                        </template>
                        <template #start-time>
                            Сентябрь
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
        <template #campuses>
            <Campus>
                <template #localisation>
                    Париж Fashion
                </template>
                <template #description>
                    Школа моды находится в Париже на левом берегу, в Латинском квартале. На площади 2500 м² предлагается профессиональное оборудование, специально предназначенное для курсов моды: три великолепных зала для изготовления выкройки, мастерская трикотажа, мастерская аксессуаров, FabLab, мастерская шелкотрафаретной печати, гостиная, фото- и видеостудия, специальный портал электронного обучения, атриум и лаборатория компьютерной графики.
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Париж  Interior Architecture and Design 
                </template>
                <template #description>
                    Кампус архитектуры и дизайна обосновался в районе Гобелен в 13-м округе Парижа! В здании бывшего производства площадью 2700 м² учащиеся имеют 12 классных комнат для обучения прикладному искусству, мастерскую для изготовления моделей и прототипов, 4 лаборатории для Mac и ПК, фабрику, оснащенную новейшими инструментами, включая 3D-принтеры, станок для лазерной резки и широкоформатный принтер, выставочный зал, амфитеатр и кафетерий.
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Париж Animation and Video Games 
                </template>
                <template #description>
                    Школа анимации и видеоигр предоставляет студентам высококачественную инфраструктуру и оборудование, комнату с зеленым экраном площадью 80 м² с цветным экраном и профессиональным освещением. , студию цифровой оценки, студию захвата движения, студию звука / пост-синхронизации и студию постпродакшна - в самом сердце Парижа.
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Париж Graphic Design 
                </template>
                <template #description>
                    Отдел графического дизайна предлагает доступ к двум фотостудиям, звуковой студии и проекционной комнате.
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Нант
                </template>
                <template #description>
                    Кампус LISAA Nantes идеально расположен в центре города, в бывшем здании плотников начала 20 века, в художественном районе Льеуникк. На полпути между историческим центром и многообещающим районом Иль-де-Нант до школы можно добраться на трамвае и автобусе, и она находится в десяти минутах ходьбы от железнодорожного вокзала SNCF.
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Ренн
                </template>
                <template #description>
                    LISAA Ренн - важный участник художественной жизни Северо-Западной Франции. В 2016 году школа предлагает новую ориентированную на будущее программу по сonnected дизайну и уделяет особое внимание обучению в области моушн-дизайна.
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                   Страсбург 
                </template>
                <template #description>
                    Кампус расположен рядом с районом Маленькая Франция, недалеко от MAMCS (Музей современного искусства) и всего в двух шагах от железнодорожного вокзала SNCF. 
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Бордо
                </template>
                <template #description>
                    Кампус LISAA Bordeaux расположен в Bassins à flot, в самом сердце необычной экосистемы: FRAC Nouvelle Aquitaine, Ubisoft, Betclic, la cité du vin.
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Тулуза
                </template>
                <template #description>
                    Кампус расположен в первом бизнес-парке региона Тулуза, Enova Labège Toulouse, в самом сердце динамичной экосистемы стартапов, крупных компаний, исследований и инноваций.
                </template>
            </Campus>
        </template>
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>